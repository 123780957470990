export default {
  'onefid': 'ONEFID',
  'telephone': '+ 49 221 95793412',
  'how to use': 'HOW TO USE',
  'customer support': 'CUSTOMER SUPPORT',
  'catalog': 'CATALOG',
  'start': 'START',
  'back': 'BACK',
  'goBack': 'GO BACK',
  'next': 'NEXT',
  'new scan': 'NEW SCAN',
  'home': 'HOME',
  'no scanner found': 'No 3D scanners found.',
  'detect scanner message': 'detecting 3D scanners in progress',
  'yes': 'YES',
  'no': 'NO',
  'measurements': 'measurements',
  'analysis': 'analysis',
  'more': 'more',
  'new user': 'New user',
  'new scan modal logged in user': 'This user is logged in\ndo you want to create\nnew scan or log out?',
  'new scan modal not logged in user': 'You clicked on  "NEW SCAN"\nthis will overwrite the current results.\nPlease choose the next action.',
  'save result': 'Save result',
  'weight': 'WEIGHT',
  'activity': 'ACTIVITY',
  'searchPlaceHolder': 'What are you looking for?',
  'loadProductMessage': 'Please wait while the product catalogue is being loaded. This may take some time...',
  'noProductFoundMessage': 'Sorry, no products were found matching your size!',
  'noSearchProductFoundMessage': 'Sorry, no products were found matching your search',
  'resetFilter': 'reset filter',
  'loadMoreProducts': 'Load more products!',
  'make new search': 'make new search',
  'In stock': 'In stock',
  'projectedStock': 'Projected stock',
  'out of stock': 'out of stock',
  'SAVE': 'SAVE',
  'FEEDBACK': 'FEEDBACK',
  'order now': 'ORDER NOW',
  'price': 'Price',
  'noInternet': 'We could not detect an Internet connection. Please check',
  'moreDetails':'more Details',
  'recommendedForYou':'RECOMMENDED FOR YOU',
  'addItem': 'Add item',
  'mainScreen': 'Main Screen',
  'createInsole': 'Create Insole',
  'insoleCreation': 'Insole creation is in progress',
  'shoeSize': 'Size',
  'insoleType': 'Insole type',
  'recommendedInsole': 'Recommended Insole: ',
  'sport': 'Sport',
  'everyday': 'Everyday',
  "chooseCustomer": "CHOOSE CORPORATE CLIENT",
  "createEvent": "CREATE EVENT",
  "createButton": "CREATE",
  "chooseButton": "SELECT",
  "adjustSize": "ADJUST SIZE",
  "comments": "Comments",
  "createProcess": "Create Process",
  "legLengthCompensation1,5cm": "LEG LENGTH COMPENSATION UP TO 1.5 CM",
  "legLengthCompensation3cm": "LEG LENGTH COMPENSATION UP TO 3 CM",
  "inOutEdgeElevation": "INNER-/OUTER EDGE ELEVATION",
  "corkWedge": "CORK WEDGE",
  "haglundFerse": "HAGLUND DEFORMITY",
  "wheelingSole": "ROLLER",
  "butterflyRoll": "BUTTERFLY ROLLER",
  "orthoOrderSuccessMessage": "We successfully\nsaved your Orthopedic shoe & insole adaptions",
  "orthopedicInsole": "ORTHOPEDIC INSOLE",
  "changeSizeButton": "Select size",
  "shoeAdaptionsModalTitle": "Adjustments",
  "listOfSizes": "List of sizes",
  "orthoOrder": "Ortho Order",
  "shoeFitting": "SHOE FITTING",
  "fullCatalog": "COMPLETE CATALOG",
  "accessoriesCatalogTitle": "Accessories",
  "repeatScanButton": "Repeat Scan",
  "items": "Items",
  "removeButton": "remove",
  "checkoutButton": "checkout",
  "cartIsEmpty": "Cart is Empty",
  "orthoInsolePopupText": "Only one orthopedic insole is allowed per order.",
  "closeButton": "Close",
  "customInsole": "Custom Insole",
  "itemsInCartWarning": "There are still items in the cart that have not been completed.\nContinue anyway?",
  "cartButton": "Cart",
  "white": "White",
  "black": "Black",

  newScan: {
    headlines: {
      mode: 'mode',
      confirmation: 'confirm'
    },
    'origin':'REGION',
    'birthdate':'YEAR OF BIRTH',
    'birthdateQuestion':'Please select your year of Birth'
  },
  gender: {
    caption: 'gender',
    female: 'female',
    male: 'male'
  },
  mode: {
    caption: 'mode',
    barefoot: 'barefoot',
    socks: 'socks',
    dark_socks: 'dark socks',
    bright_socks: 'bright socks',
  },
  auth: {
    login: 'login',
    register: 'register',
    confirm: 'confirm',
    connect: 'connect',
    createAccount: 'create account',
    logIn: 'log in',
    logOut: 'log out',
    addEmail: 'Add email',
    changeEmail: 'Change email',
    stayConnected: 'stay connected',
    logoutMessage: 'This user is logged in\ndo you want to log out?',
    passwordPlaceholder: 'Enter your Password',
    emailPlaceholder: 'Enter your Email',
    employeenumberPlaceholder: 'Enter your Employee number',
    emailRequired: 'Email address is required.',
    emailInvalid: 'Email address is invalid.',
    passwordRequired: 'Password is required.',
    firstNameRequired: 'First name is required.',
    lastNameRequired: 'Last name is required.',
    dateOfBirthRequired: 'Date of birth is required.',
    employeeNumberRequired: 'Employee Number is required.',
    unableToLogin: 'Unable to log in with provided credentials',
    emailExists: 'email user with this email already exists.',
    employeeNumberExists: 'A user with the same employee number exists.',
    unique: 'email user with this email already exists.',
    confirmEmailAddedMessage: 'Your email has been added',
    addEmailInfo: 'Add your email to access your data in all supported platforms',
    emailAddedInstructions: 'You will receive an email shortly to set a password',
    provideAllFields: 'Please fill in all fields',
    genericError: 'An error occurred, please try again in a few moments.',
    succesRegisterMessage: [
      'We successfully\nsaved your One',
      'Fitting',
      'ID'
    ],
    shoepassionSuccesRegisterMessage: [
      'We successfully\nsaved your ',
      'Shoepassion',
      'ID'
    ],
    confirmRegisterMessage: [
      'Your',
      'One Fitting ID',
      'account has been created! Just click on the link we sent you to confirm and get free access',
      'anytime anywhere'
    ],
    shoepassionConfirmRegisterMessage: [
      'Your',
      'ShoepassionID ',
      'account has been created! Just click on the link we sent you to confirm and get free access',
      'anytime anywhere'
    ],
    termsAndConditions: ['I\'ve read and accept the', 'Data protection regulations'],
    oneFittingIDCreation: 'One Fitting ID creation',
    shoepassionNewsletter: ['register to Shoepassion Newsletter and accept', '']
  },

  support: {
    caption: 'get in touch',
    selectScanner: 'select scanner',
    calibration: 'calibration',
    form: {
      textPlaceholder: 'Describe the problem',
      namePlaceholder: 'Full name',
      sendButtonText: 'send'
    },
    bottomCards: {
      customerSupport: {
        caption: 'customer support',
        phoneNumber: 'OneFID: 06192 7755566',
        emailAddress: 'scanner-support@onefid.com'
      },
      scannerId: {
        caption: 'scanner id'
      }
    }
  },
  scan: {
    captionLeft: 'PLEASE PUT YOUR LEFT FOOT ON',
    succescaptionLeft: 'LEFT FOOT SCANNED',
    captionRight: 'PLEASE PUT YOUR RIGHT FOOT ON',
    succescaptionRight: 'RIGHT FOOT SCANNED',
    captionMulti: 'PLEASE PUT BOTH YOUR FEET ON',
    succescaptionMulti: 'BOTH YOUR FEET SCANNED',
    start: 'START',
    left: 'LEFT',
    right: 'RIGHT',
    both: 'BOTH',
    inProgress: 'SCAN IN PROGRESS',
    setParameter: 'SETTING UP CAMERA',
    capture: 'CAPTURING FEET',
    process: 'PROCESSING 3D SCAN',
    succesScanMessage: [
      'SCAN SUCCESSFUL',
      'We are creating your One',
      'FID',
      'scan',
      'You can step off the scanner.\nYour scan data will be ready in a few seconds!'
    ],
    faildScanMessage: [
      'SCAN FAILED',
      'We did not succeed creating your One',
      'FID',
      'scan.',
      'There seems to be a problem. Please try again.\nContact us if the problem did not get resolved.',
      'SCAN AGAIN',
      'CANCEL'
    ],
    error: {
      title: 'Unfortunately, something did not work.',
      callSupport: 'If this does not solve the problem, please contact support.',
      genericError: 'An error occurred.',
      scannerBusy: 'Scanner is busy, please scan again in few seconds',
      foamBoxNotImplemented: 'An error occurred.',
      log_output_too_short: 'An error occurred.',
      an_error_occured: 'An error occurred.',
      make_missing_target: 'Please contact support.',
      incompatible_configuration_file: 'Please contact support.',
      light_hardware_not_accessible: 'Please switch off the scanner and switch it on again after ten seconds. Then repeat the measurement.',
      camera_initialization_fails: 'A sensor error has occurred. Please wait for the scanner to reset and then repeat the measurement.',
      camera_provides_no_frames: 'A sensor error has occurred. Please wait for the scanner to reset and then repeat the measurement.',
      meshlab_subdivision_surface_fails: 'Please leave the scanner, clean the glass plate, roll up your trousers again if necessary, place your feet on the scanner and repeat the measurement.',
      meshlab_error: 'Please leave the scanner, clean the glass plate, roll up your trousers again if necessary, place your feet on the scanner and repeat the measurement.',
      foot_length_unexpected: 'Please leave the scanner, clean the glass plate, roll up your trousers again if necessary, place your feet on the scanner and repeat the measurement.',
      foot_width_unexpected: 'Please leave the scanner, clean the glass plate, roll up your trousers again if necessary, place your feet on the scanner and repeat the measurement.',
      glass_plate_covered: 'Please clear the glass plate.',
      foot_touches_bounding_volume: 'Please position the feet according to the marking.',
    }
  },
  insole: {
    caption: 'INSOLE',
    marketingText: 'Base upon the foot analysis we recommend additional support with an insole.\nThis prevents fatigue and overloading.',
    q1caption: 'Do you have pain in your feet?',
    q2caption: 'Do you wear orthopedic insoles or are you undergoing orthopedic treatment due to foot problems?',
    q3caption: 'Results',
    insoleTypeRequired: 'Insole type is required',
    sizeRequired: "Size is required!",
    recommendation: {
      button: 'CATALOG',
      0: {
        mainMessage: `No insole needed`,
        subMessage: 'Based upon our foot analysis you do not have any foot malpositioning.',
        conclusion: 'NO INSOLE RECOMMENDED'
      },
      1: {
        mainMessage: 'Please consult with an orthopedic doctor',
        subMessage: 'Based upon our foot analysis we recommend a detailed consultation by an orthopedist or podiologist!',
        conclusion: 'INDIVIDUAL INSOLE NEEDED'
      },
    }
  },
  MeasurementScale: {
    foot_type: 'foot type',
    heel_angle: 'heel angle',
    arch_height: 'arch height',
    foot_width: 'foot width',
    flat: 'Flat',
    normal: 'Normal',
    high: 'High',
    low: 'Low',
    pronation: 'Pronation',
    neutral: 'Neutral',
    supination: 'Supination',
    narrow: 'Narrow',
    wide: 'Wide',
    millimeters: 'millimeters',
    cm: 'centimeter',
    length: 'length',
    width: 'width',
    left: 'left',
    right: 'right',
    heightInstepPoint: 'Height instep',
    widthHeel: "Width heel"
  },
  Order: {
    confirmMessage: 'Are you sure you want to continue the order process',
    successMessage: 'Your order was successfully submitted!',
    failureMessage: 'Something went wrong! trying to process your order.',
    cotaFailureMessage: 'You order Limit is reached you can not order this shoe!',
    success: 'Success',
  },
  CustomiseProduct: {
    confirmCustomisation: 'Do you want customise your shoe?',
    weight: {
      'upto65Kg': 'up to 65 Kg',
      'from110Kg': 'from 110 Kg'
    },
    activity: {
      mostlyStanding: 'Mostly Standing',
      mostlyWalking: 'Mostly Walking',
      mostlySitting: 'Mostly Sitting'
    }
  },
  SafetyCatalog: {
    'title': "Safety catalog",
    'work shoe': 'WORK SHOE',
    'work boot': 'WORK BOOT',
    'work sandal': 'WORK SANDAL',
  },
  RunningCtalog: {
    title: 'Running Catalog',
    runningExperience: 'RUNNING EXPERIENCE',
    runningExperienceQuestion: "What's your running experience?",
    occasionally: 'Occasionally (1-2x)',
    regularly: 'Regularly (2-3x)',
    ambitious: 'Ambitious (>3x)',
    application: 'USAGE',
    applicationQuestion: 'What do you want to use the shoe for?',
    walking: 'Walking',
    competition: 'Competition',
    training: 'Training',
    terrain: 'TERRAIN',
    terrainQuestion: 'Where do you want to run?',
    trail: 'Trail/Offraod',
    road: 'Road / Treadmill',
    track: 'Running track',
    knee: 'KNEE AXIS',
    kneeQuestion: 'How is your knee axis oriented?',
    concave: 'In-knee (valgus)',
    neutral: 'Neutral',
    convex: 'Out-knee (varus)',
    heel: 'HEEL ANGLE',
    heelQuestion: 'How does you heel behave during running?',
    pronation: 'Inside (Pronation)',
    neutralheel: 'Neutral',
    supination: 'Outside (Supination)',
    cushioning: 'CUSHIONING',
    cushioningQuestion: 'Which cushioning experience do you prefer?',
    soft: 'Soft',
    hard: 'hard',
    natural: 'Natural running',
    normal: 'Normal',
    ageGroup: 'AGE GROUP',
    ageGroupQuestion: "What's your age group?",
    kids: 'Kids',
    adults: 'Adults',
    noProducts: 'No Products',
    filterCriteriaMessage: 'Please change your search criteria or change the filter settings.',
    resetFilter: 'Reset filter'
  },
  SkiCatalog: {
    title: 'Ski Catalog',
    skill: 'SKILL',
    skillQuestion: "What's your Ski experience?",
    beginner: 'Beginner',
    intermediate: 'Intermediate',
    expert: 'Expert',
    race: 'Race',
    terrain: 'TERRAIN',
    terrainQuestion: 'Where do you want to ski?',
    allmontain: 'All Mountain',
    piste: 'Piste',
    freeride: 'Free Ride',
    hard: 'Hard',
    weight: 'Weight',
    weightQuestion: "What's your weight?",
    upto60: 'up to 60 kg',
    '60to80': '60 to 80 kg',
    '80to100': '80 to 100 kg',
    morethan100: 'More than 100 kg',
    ageGroup: 'AGE GROUP',
    ageGroupQuestion: "What's your age group?",
    kids: 'Kids',
    adults: 'Adults',
    noProducts: 'No Products',
    filterCriteriaMessage: 'Please change your search criteria or change the filter settings.',
    resetFilter: 'Reset filter'
  },
  FilterCatalog: {
    title: 'Filter Options',
    category: ' Category',
    brand: 'Brand',
    color: 'Color',
    leahtherType: 'Leather Type',
    underground: 'Terrain',
    support: 'Support',
    sale: 'Sale',
    showProducts: 'show products'
  },
  secondaryRecommendatiom: {
    yourSize: 'YOUR SIZE',
    message1: 'Your foot length is on the edge of two sizes which both fit you!',
    message2: 'If you like your shoes to fit tight, choose: ',
    message3: 'If you like your shoes to fit loose, choose: '
  },
  expiviConfigurator: "Shoe Configurator",
  advertisingOnefid: "OneFID - Scan your feet and find shoes that fit"
};
