// import Textarea from 'react-native-textarea';
import React, { useState, useEffect } from "react";
import { Image, PixelRatio, Platform, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";
import { Icons } from "../../assets";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { useNavigation } from "@react-navigation/native";
import { useGlobalState } from "../../shared/state";
import { I18N, sleep } from "../../shared/util";
import { Fonts, Styles } from "../../styles";
import { sendGridEmail } from "react-native-sendgrid";
import ScannerService from "../../shared/services/scanner-service";

const Support: React.FC = (): JSX.Element => {
  const [currentScanner] = useGlobalState("currentScanner");
  const [language] = useGlobalState("language");
  const [, setRoute] = useGlobalState("currentScreen");
  const [Colors] = useGlobalState("colors");
  const [eventName] = useGlobalState("eventName");

  const [validated, setValidated] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [textInputError, setTextInputError] = useState<string | undefined>(undefined);
  const [nameInput, setNameInput] = useState("");
  const [nameInputError, setNameInputError] = useState<string | undefined>(undefined);
  const [sendButtonDisabled, setSendButtonDisabled] = useState<Boolean>(false);
  const SUPPORTCONTACT: string = "scanner-support@onefid.com";

  const navigation = useNavigation();
  const i18n = I18N(language);

  const navigate = (route: string | undefined, options: any = undefined) => {
    if (route) {
      navigation.push(route, options);
      setRoute(route);
    }
  };

  useEffect(() => {
    if (sendButtonDisabled) sleep(10000).then(() => setSendButtonDisabled(false));
  }, [sendButtonDisabled]);

  const sendEmail = () => {
    if (textInput.length > 1 && nameInput.length > 1 && !sendButtonDisabled) {
      const ContactDetails = `<html>
          <body>
            <div>${textInput}</div>
            <p><b>Name: </b>${nameInput}</p>
            <p><b>Scanner Token: </b>${currentScanner["scanner-token"]}</p>
          </body>
       </html>`;
      const SUBJECT = `Scanner Name: ${currentScanner["scanner-name"]}`;
      const sendRequest = sendGridEmail(globalThis.env.SENDGRID_KEY, SUPPORTCONTACT, SUPPORTCONTACT, SUBJECT, ContactDetails, "text/html");
      sendRequest
        .then((response) => {
          setSendButtonDisabled(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const validateInput = (): boolean => {
    if (!textInput || textInput.length < 1) {
      setTextInputError("This field is required. Please enter a problem description.");
    } else {
      setTextInputError(undefined);
    }
    if (!nameInput || nameInput.length < 1) {
      setNameInputError("This field is required. Please enter your full name.");
    } else {
      setNameInputError(undefined);
    }

    setValidated(true);
    return textInputError === undefined && nameInputError === undefined;
  };

  return (
    <LayoutWrapper showHeaderCloseButton showHeaderLogo showLanguageButton showMenuButtons={false}>
      <View style={[Styles.contentWrapperFull, Styles.column]}>
        <View style={styles.wrapper}>
          <View style={styles.header}>
            <Text style={[Fonts.pageTitle, Colors.black]}>{i18n.t("support.caption").toUpperCase()}</Text>
          </View>
          <View style={styles.contentWrapper}>
            <View style={styles.contactFormWrapper}>
              <View style={textInputError ? [styles.formTextareaWrapper, Styles.errorBorder] : styles.formTextareaWrapper}>
                <View style={styles.formTextareaIconsWrapper}>
                  {validated ? (
                    textInputError ? (
                      <Image source={Icons.support.errorIcon} style={styles.formTextareaErrorIcon} />
                    ) : (
                      <Image source={Icons.support.checkmarkIcon} style={styles.formTextareaCheckmarkIcon} />
                    )
                  ) : undefined}
                  <Image source={Icons.support.mailIcon} style={styles.formTextareaMailIcon} />
                </View>
                <TextInput
                  multiline
                  style={[styles.formTextarea, Fonts.textInput, Colors.darkGray0B]}
                  numberOfLines={5}
                  onChangeText={(text) => setTextInput(text)}
                  placeholder={i18n.t("support.form.textPlaceholder")}
                />
              </View>
              <View style={styles.formTextareaErrorMessage}>{textInputError && <Text style={[Fonts.text, Colors.error]}>{textInputError}</Text>}</View>
              <View style={styles.formBottomRowWrapper}>
                <View style={nameInputError ? [styles.formNameInputWrapper, Styles.errorBorder] : styles.formNameInputWrapper}>
                  <TextInput style={[styles.formNameInput, Fonts.textInput, Colors.darkGray0B]} onChangeText={(text) => setNameInput(text)} placeholder={i18n.t("support.form.namePlaceholder")} />
                  {nameInputError ? <Image source={Icons.support.errorIcon} style={styles.formNameInputErrorIcon} /> : <Image source={Icons.loginIconOutlined} style={styles.formNameInputUserIcon} />}
                </View>
                <TouchableOpacity
                  style={sendButtonDisabled ? [styles.formSendButton, { backgroundColor: Colors.darkGray3a.color }] : styles.formSendButton}
                  disabled={sendButtonDisabled ? true : false}
                  onPress={() => {
                    validateInput(), sendEmail();
                  }}
                  activeOpacity={0.6}
                >
                  <Text style={[Fonts.text]}>{i18n.t("support.form.sendButtonText").toUpperCase()}</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.buttonsWrapper}>
              {currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "hoffmann group" && (
                <View style={styles.sideButtonWrapper}>
                  <TouchableOpacity style={styles.sideButton} onPress={() => navigate("CreateEvent")}>
                    <Image source={Icons.support.corporate} style={styles.sideButtonIcon} />
                    <Text style={[Fonts.textHuge, Fonts.size20]}>{i18n.t("createEvent").toUpperCase()}</Text>
                    {eventName && (
                      <View style={{ alignItems: "center", justifyContent: "center", width: "auto", height: "20%", marginTop: "2%", padding: "3%", backgroundColor: Colors.mainColorDark.color }}>
                        <Text style={[Fonts.textHuge, Fonts.size20, { color: "white" }]}>{eventName}</Text>
                      </View>
                    )}
                  </TouchableOpacity>
                </View>
              )}
              {currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "elten" && !currentScanner.hideStoreSelector && (
                <View style={styles.sideButtonWrapper}>
                  <TouchableOpacity style={styles.sideButton} onPress={() => navigate("ChooseCustomer")}>
                    <Image source={Icons.support.corporate} style={styles.sideButtonIcon} />
                    <Text style={[Fonts.textHuge, Fonts.size20]}>{i18n.t("chooseCustomer").toUpperCase()}</Text>
                  </TouchableOpacity>
                </View>
              )}
              <View style={styles.sideButtonWrapper}>
                <TouchableOpacity style={styles.sideButton} onPress={() => navigate("DetectedScanners")}>
                  <Image source={Icons.support.selectScannerIcon} style={styles.sideButtonIcon} />
                  <Text style={[Fonts.textHuge, Fonts.size20]}>{i18n.t("support.selectScanner").toUpperCase()}</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.sideButtonWrapper}>
                <TouchableOpacity
                  style={styles.sideButton}
                  onPress={() => {
                    ScannerService.calibrateScanner().then((res) => {
                      console.log("res", res);
                    });
                  }}
                >
                  <Image source={Icons.support.calibrationIcon} style={styles.sideButtonIcon} />
                  <Text style={[Fonts.textHuge, Fonts.size20]}>{i18n.t("support.calibration").toUpperCase()}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <View style={styles.formNameInputErrorMessage}>{nameInputError && <Text style={[Fonts.text, Colors.error]}>{nameInputError}</Text>}</View>
          <View style={styles.contactInformationWrapper}>
            <View style={styles.customerSupportWrapper}>
              <View style={styles.contactInformationCaption}>
                <Text style={[Fonts.textHuge, Colors.darkGray0B]}>{i18n.t("support.bottomCards.customerSupport.caption").toUpperCase()}</Text>
              </View>
              <View style={styles.contactInformation}>
                <Image source={Icons.support.mailIconBlack} style={styles.contactInformationPhoneIcon} />
                <Text style={[Fonts.textSmaller, Colors.darkGray0B]}>{i18n.t("support.bottomCards.customerSupport.emailAddress").toUpperCase()}</Text>
              </View>
            </View>
            <View style={styles.contactInformationSeparator} />
            <View style={styles.scannerIdWrapper}>
              <View style={styles.contactInformation}>
                <Text style={[Fonts.textHuge, Colors.darkGray0B]}>{i18n.t("support.bottomCards.scannerId.caption").toUpperCase()}</Text>
              </View>
              <View style={styles.contactInformation}>
                <Text style={[Fonts.textSmaller, Colors.darkGray0B]}>{currentScanner && currentScanner["scanner-name"] && currentScanner["scanner-name"].toUpperCase()}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </LayoutWrapper>
  );
};

export default Support;

const styles = StyleSheet.create({
  wrapper: {
    height: "100%",
    width: "85%",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  header: {
    height: "12%",
    width: "100%",
    paddingTop: "2%",
    paddingBottom: "3%",
    paddingLeft: "2%",
  },
  contentWrapper: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  contactFormWrapper: {
    width: "50%",
    height: "100%",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formTextareaWrapper: {
    height: "62%",
    minHeight: "62%",
    width: "100%",
    backgroundColor: "#f0eff1",
    borderRadius: 9 / PixelRatio.get(),
    borderColor: "#707070",
    borderWidth: 0.5 / PixelRatio.get(),
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
  },
  formTextarea: {
    height: "100%",
    width: "100%",
    padding: "3%",
  },
  formTextareaIconsWrapper: {
    height: "10%",
    minHeight: "10%",
    maxHeight: "10%",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: "2.5%",
    marginTop: "1%",
  },
  formTextareaCheckmarkIcon: {
    width: "7%",
    height: "60%",
    resizeMode: "contain",
    marginRight: "1%",
  },
  formTextareaErrorIcon: {
    width: "7%",
    height: "55%",
    resizeMode: "contain",
    marginRight: "1%",
  },
  formTextareaMailIcon: {
    width: "10%",
    height: "100%",
    resizeMode: "contain",
  },
  formTextareaErrorMessage: {
    width: "100%",
    height: "5%",
    paddingTop: "2%",
  },
  formBottomRowWrapper: {
    height: "25%",
    width: "100%",
    marginTop: "2%",
    marginBottom: "2.2%",
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formNameInputWrapper: {
    width: "65%",
    height: "70%",
    backgroundColor: "#f0eff1",
    borderRadius: 9 / PixelRatio.get(),
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  formNameInput: {
    width: "70%",
    height: "50%",
    flex: 1,
    paddingLeft: "5%",
  },
  formNameInputErrorMessage: {
    position: "absolute",
    top: "95%",
    width: "100%",
    height: "5%",
    marginTop: ".25%",
  },
  formNameInputUserIcon: {
    width: "20%",
    height: "50%",
    resizeMode: "contain",
  },
  formNameInputErrorIcon: {
    width: "20%",
    height: "50%",
    resizeMode: "contain",
    marginRight: "-1%",
  },
  formSendButton: {
    maxWidth: "35%",
    height: "70%",
    marginLeft: "5%",
    borderColor: "#707070",
    borderWidth: 0.5 / PixelRatio.get(),
    borderRadius: 6 / PixelRatio.get(),
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonsWrapper: {
    maxWidth: "50%",
    height: "100%",
    marginLeft: "3.5%",
    paddingBottom: "6.7%",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  sideButtonWrapper: {
    height: "48%",
    width: "48%",
    shadowColor: "#000",
    shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
    shadowOpacity: 0.16,
    shadowRadius: 6 / PixelRatio.get(),
    elevation: 1,
  },
  sideButton: {
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 17 / PixelRatio.get(),
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  sideButtonSelectScanner: {
    resizeMode: "contain",
    maxHeight: "27%",
  },
  sideButtonIcon: {
    resizeMode: "contain",
    maxHeight: "11%",
    height: "100%",
    width: "100%",

    marginBottom: "5%",
  },
  contactInformationWrapper: {
    position: "absolute",
    height: "13%",
    width: "100%",
    top: "100%",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  contactInformation: {
    height: "100%",
    width: "100%",
    flex: Platform.OS === "web" ? undefined : 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: 0,
    paddingLeft: 0,
  },
  contactInformationCaption: {},
  contactInformationPhoneIcon: {
    resizeMode: "contain",
    height: "100%",
    maxHeight: Platform.OS === "web" ? "50%" : "30%",
    width: "10%",
    marginLeft: "-3%",
    paddingLeft: 0,
  },
  customerSupportWrapper: {
    width: "50%",
    height: "100%",
    padding: "2%",
    paddingLeft: "10%",
  },
  scannerIdWrapper: {
    width: "50%",
    height: "100%",
    padding: "2%",
    paddingLeft: "15%",
  },
  contactInformationSeparator: {
    height: "80%",
    width: 1 / PixelRatio.get(),
    backgroundColor: "#0b0b0b",
    marginTop: "2.3%",
  },
});
