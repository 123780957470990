import { View } from "react-native";
import React from "react";
import { StyleSheet } from "react-native";
import { Icons } from "../../assets";
import { useGlobalState } from "../../shared/state";
import Auth from "../Buttons/auth-button/Auth";
import Logo from "./Logo";
import CircleButton from "../Buttons/generic-button/CircleButton";
import { Carts } from "../../shared/model/types";

type Props = {
  closeButton?: boolean;
  logo?: boolean;
};

const Header = ({ closeButton, logo }: Props): JSX.Element => {
  const [currentScanner] = useGlobalState("currentScanner");
  const [Colors] = useGlobalState("colors");
  const [carts] = useGlobalState("carts");

  function getTotalItemsInCart(carts: Carts): number {
    let totalItems = 0;

    for (const cartId in carts) {
      const cart = carts[cartId];
      if (cart.items) {
        totalItems += cart.items.length;
      }
    }

    return totalItems;
  }

  const closeButtonColor = Colors.mainColorDark.color;

  return (
    <View style={styles.header}>
      <View style={styles.mainContainer}>
        <View style={styles.leftContainer}>
          <View style={styles.centeredContent}>
            {closeButton ? (
              <CircleButton color={closeButtonColor} navigateTo="CloseButton" icon={Icons.closeIconTransparent} deactivated={currentScanner ? false : true} />
            ) : (
              <CircleButton
                color={currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "onefid" ? Colors.white.color : Colors.mainColorDark.color}
                navigateTo="Support"
                icon={currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "onefid" ? Icons.infoIconBlack : Icons.infoIconWhite}
                deactivated={currentScanner ? false : true}
              />
            )}
          </View>
        </View>
        <View style={styles.rightContainer}>
          <View style={styles.centeredContent}>
            <Auth />
          </View>
        </View>
        {currentScanner && currentScanner.enableOrder && (
          <View style={styles.rightContainer}>
            <View style={styles.centeredContent}>
              <View style={[styles.notification, {backgroundColor: Colors.mainColorLight.color}]}>
                <p style={{ color: "white" }}>{getTotalItemsInCart(carts)}</p>
              </View>
              <CircleButton
                color={closeButtonColor}
                navigateTo="CartModal"
                icon={currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "onefid" ? Icons.cartBlack : Icons.cartWhite}
                deactivated={false}
              />
            </View>
          </View>
        )}
      </View>
      <View style={styles.logo}>{logo ? <Logo /> : <View />}</View>
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "row",
    width: "11%",
    height: "100%",
    alignItems: "center",
  },
  leftContainer: {
    width: "50%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  rightContainer: {
    width: "50%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  centeredContent: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  header: {
    height: "15%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    padding: "1%",
  },
  logo: {
    width: "30%",
    height: "100%",
    alignItems: "flex-end",
    alignContent: "flex-end",
    justifyContent: "center",
  },
  notification: {
    width: 25,
    height: 25,
    borderRadius: 10,
    top: 45,
    position: "fixed",
    zIndex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
