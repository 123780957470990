import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity, ImageBackground } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";
import { Icons, background } from "../../assets";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { Styles } from "../../styles";
import { useGlobalState } from "../../shared/state";
import { I18N } from "../../shared/util";
import ProductService from "../../shared/services/products-service";
import { getMatchedproducts } from "../../shared/products";
import { Gender } from "../../shared/model/enums";
import CustomTextInput from "../../components/Text/CustomTextInput";

const CreateEvent: React.FC = (): JSX.Element => {
  const navigation = useNavigation();
  const [currentScan] = useGlobalState("currentScan");
  const [currentUser] = useGlobalState("currentUser");
  const [language] = useGlobalState("language");
  const i18n = I18N(language);
  const [Colors] = useGlobalState("colors");
  const [, setEventName] = useGlobalState("eventName");

  const handleSubmit = async () => {
    // ProductService.createEvent(eventName);
    if (currentScan || currentUser) {
      navigation.navigate("MainApp", { screen: "Measurements" });
    } else {
      navigation.navigate("MainApp", { screen: "Home" });
    }
  };

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton showHeaderLogo showBackButton={false}>
        <View style={styles.container}>
          <Text style={styles.titleText}>{i18n.t("createEvent")}</Text>
          <Image source={Icons.support.corporate} style={styles.iconContainer} />
          <View style={styles.inputsWrapper}>
            <CustomTextInput placeholder={"Event Name"} errorValidation={''} parentCallback={(name) => setEventName(name)} />
          </View>
          <TouchableOpacity style={[styles.button, { backgroundColor: Colors.mainColorDark.color }]} onPress={handleSubmit}>
            <Text style={styles.buttonText}>{i18n.t("createButton")}</Text>
          </TouchableOpacity>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default CreateEvent;

const styles = StyleSheet.create({
  container: {
    width: "70%",
    height: "90%",
    alignSelf: "center",
    backgroundColor: "white",
    padding: "5%",
  },
  formWrapper: {
    width: "100%",
    height: "75%",
    alignSelf: "center",
  },
  inputsWrapper: {
    width: "50%",
    height: "10%",
    marginTop: "2%",
    marginBottom: "2%",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "1.2%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    justifyContent: "space-between",
    alignSelf: "center",
  },
  iconContainer: {
    width: "10%",
    height: "10%",
    resizeMode: "contain",
    alignSelf: "center",
    margin: "2%",
  },
  titleText: {
    fontFamily: "Montserrat-Black",
    fontSize: 21,
    fontWeight: "900",
    textAlign: "center",
    textTransform: "uppercase",
    margin: "2%",
  },
  picker: {
    width: "90%",
    height: "50%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    // marginLeft: "5%",
  },
  button: {
    width: 195,
    height: 42,
    borderRadius: 17,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  buttonText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    color: "#ffffff",
    textTransform: "uppercase",
  },
});
