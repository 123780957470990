import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Login from '../screens/Web/Login';
import Register from '../screens/Web/Register';
import RegisterConfirmation from '../screens/Auth/RegisterConfirmation';
import CatalogTree from '../screens/Catalog/CatalogTree';
import DetectedScanners from '../screens/DetectedScanners/DetectedScanners';
import Home from '../screens/Home/Home';
import InsoleRecommendation from '../screens/InsoleRecommendation/InsoleRecommendation';
import Measurements from '../screens/Measurements/Measurements';
import LogoutModal from '../screens/Auth/LogoutModal';
import NewScanModal from '../screens/Scan/NewScanModal';
import RegisterModal from '../screens/Auth/RegisterModal';
import NewScan from '../screens/Web/NewScan';
import NewScanGenderSelection from '../screens/Scan/NewScanGenderSelection';
import NewScanModeSelection from '../screens/Scan/NewScanModeSelection';
import ScanBothFeet from '../screens/Scan/ScanBothFeet';
import ScanSuccessfulModal from '../screens/Scan/ScanSuccessfulModal';
import ScanLeftFoot from '../screens/Scan/ScanLeftFoot';
import ScanRightFoot from '../screens/Scan/ScanRightFoot';
import Support from '../screens/Support/Support';
import DataProtectionModal from '../screens/Auth/DataProtectionModal';
import LoginMeasurementModal from '../screens/MeasurementScreenModals/LoginMeasurementModal';
import RegisterMeasurementModal from '../screens/MeasurementScreenModals/RegisterMeasurementModal';
import LogoutMeasurementModal from '../screens/MeasurementScreenModals/LogoutMeasurementModal';
import NewScanMeasurementModal from '../screens/MeasurementScreenModals/NewScanMeasurementModal';
import OrderModal from '../screens/Order/OrderModal';
import CartModal from '../screens/Order/cart';
import CustomiseProductModal from '../screens/Order/customiseProductOrderModal';
import RunningCatalog from '../screens/Catalog/RunningCatalog';
import Catalog from '../screens/Catalog/Catalog';
import AccessoriesCatalog from '../screens/Catalog/AccessoriesCatalog';
import LogoutScreen from '../screens/Web/LogoutScreen';
import CatalogFilter from '../screens/Catalog/CatalogFilter';
import PreFilterCatalog from '../screens/Catalog/PreFilterCatalog';
import BarCodeScanner from '../components/BarCodeScanner/BarCodeScanner';
import ProductOverview from '../screens/Product/ProductOverview';
import ProductDetail from '../screens/Web/ProductDetail';
import ProductDetailModal from '../screens/Product/ProductDetailModal';
import Order from '../screens/Web/Order';
import CustomiseProducOrder from '../screens/Web/CustomiseProductOrder';
import InsoleDetailModal from '../screens/InsoleRecommendation/InsoleDetailModal';
import SkiCatalog from '../screens/Catalog/SkiCatalog';
import LoginModal from '../screens/Auth/LoginModal';
import NewScanUserOriginSelection from '../screens/Scan/NewScanUserOriginSelection';
import NewScanUserBirthYear from '../screens/Scan/NewScanUserBirthYear';
import CrossProductDetailModal from '../screens/Product/CrossProductDetailModal';
import CrossProductDetail from '../screens/Web/CrossProductDetail';
import AddEmailScreen from '../screens/Web/AddEmailScreen';
import CreationModal from '../screens/InsoleRecommendation/CreationModal';
import ChooseCustomer from '../screens/Support/ChooseCustomer';
import CreateEvent from '../screens/Support/CreateEvent';
import OrthoRegisterModal from '../screens/OrthopedicOrder/OrthoRegisterModal';
import ChangeProductSizeModal from '../screens/Product/ChangeProductSizeModal';
import OrthopedicInsoleOptionsModal from '../screens/OrthopedicOrder/OrthopedicInsoleOptionsModal';
import OrthopedicShoesOptionsModal from '../screens/OrthopedicOrder/OrthopedicShoesOptionModal';

const Stack = createStackNavigator();

const MainNavigator = () => {
  return (
    <Stack.Navigator 
    initialRouteName="DetectedScanners" 
    screenOptions={{
      headerShown: false,
      headerMode: 'screen',
    }}
    >
      <Stack.Screen name="DetectedScanners" component={DetectedScanners} />
      <Stack.Screen name="Home" component={Home} />
      <Stack.Screen name="Support" component={Support} />
      <Stack.Screen name="Catalog" component={Catalog} />
      <Stack.Screen name="PreFilterCatalog" component={PreFilterCatalog} />
      <Stack.Screen name="RunningCatalog" component={RunningCatalog} />
      <Stack.Screen name="SkiCatalog" component={SkiCatalog} />
      <Stack.Screen name="Login" component={Login} />
      <Stack.Screen name="ChooseCustomer" component={ChooseCustomer} />
      <Stack.Screen name="CreateEvent" component={CreateEvent} />
      <Stack.Screen name="Register" component={Register} />
      <Stack.Screen name="Logout" component={LogoutScreen} />
      <Stack.Screen name="RegisterConfirmation" component={RegisterConfirmation} />
      <Stack.Screen name="Measurements" component={Measurements} />
      <Stack.Screen name="NewScan" component={NewScan} />
      <Stack.Screen name="NewScanGenderSelection" component={NewScanGenderSelection} />
      <Stack.Screen name="NewScanUserOriginSelection" component={NewScanUserOriginSelection} />
      <Stack.Screen name="NewScanUserBirthYear" component={NewScanUserBirthYear} />
      <Stack.Screen name="NewScanModeSelection" component={NewScanModeSelection} />
      <Stack.Screen name="ScanLeftFoot" component={ScanLeftFoot} />
      <Stack.Screen name="ScanRightFoot" component={ScanRightFoot} />
      <Stack.Screen name="CatalogProducts" component={ProductOverview} />
      <Stack.Screen name="AccessoriesCatalog" component={AccessoriesCatalog} />
      <Stack.Screen name="ScanSuccessfulModal" component={ScanSuccessfulModal} />
      <Stack.Screen name="CatalogTree" component={CatalogTree} />
      <Stack.Screen name="ProductDetail" component={ProductDetail} />
      <Stack.Screen name="CatalogFilter" component={CatalogFilter} />
      <Stack.Screen name="ScanBothFeet" component={ScanBothFeet} />
      <Stack.Screen name="InsoleRecommendation" component={InsoleRecommendation} />
      <Stack.Screen name="BarCodeScanner" component={BarCodeScanner} />
      <Stack.Screen name="Order" component={Order} />
      <Stack.Screen name="CustomiseProducOrder" component={CustomiseProducOrder} />
      <Stack.Screen name="CrossProductDetail" component={CrossProductDetail} />
      <Stack.Screen name="AddEmailScreen" component={AddEmailScreen} />
    </Stack.Navigator>
  );
};

const RootNavigator = () => {
  return (
    <Stack.Navigator
      initialRouteName="MainApp"
      presentation="modal"
      defaultNavigationOptions={{
        gesturesEnabled: false,
      }}
      screenOptions={{
        headerShown: false,
        cardStyle: {
          backgroundColor: 'rgba(0, 0, 0, 0)',
          opacity: 1,
        },
        transitionConfig: () => ({
          containerStyle: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
        }),
      }}
    >
      <Stack.Screen name="MainApp" component={MainNavigator} />
      <Stack.Screen name="LogoutModal" component={LogoutModal} />
      <Stack.Screen name="LoginModal" component={LoginModal} />
      <Stack.Screen name="RegisterModal" component={RegisterModal} />
      <Stack.Screen name="OrthoRegisterModal" component={OrthoRegisterModal} />
      <Stack.Screen name="CartModal" component={CartModal} />
      <Stack.Screen name="OrthopedicInsoleOptionsModal" component={OrthopedicInsoleOptionsModal} />
      <Stack.Screen name="OrthopedicShoesOptionsModal" component={OrthopedicShoesOptionsModal} />
      <Stack.Screen name="ChangeSizeModal" component={ChangeProductSizeModal} />
      <Stack.Screen name="NewScanModal" component={NewScanModal} />
      <Stack.Screen name="ProductDetailModal" component={ProductDetailModal} />
      <Stack.Screen name="LoginMeasurementModal" component={LoginMeasurementModal} />
      <Stack.Screen name="RegisterMeasurementModal" component={RegisterMeasurementModal} />
      <Stack.Screen name="LogoutMeasurementModal" component={LogoutMeasurementModal} />
      <Stack.Screen name="NewScanMeasurementModal" component={NewScanMeasurementModal} />
      <Stack.Screen name="OrderModal" component={OrderModal} />
      <Stack.Screen name="CreationModal" component={CreationModal} />
      <Stack.Screen name="CustomiseProductModal" component={CustomiseProductModal} />
      <Stack.Screen name="InsoleDetailModal" component={InsoleDetailModal} />
      <Stack.Screen name="DataProtectionModal" component={DataProtectionModal} />
      <Stack.Screen name="CrossProductDetailModal" component={CrossProductDetailModal} />
    </Stack.Navigator>
  );
};

const Routes = () => {
  return (
    <NavigationContainer>
      <RootNavigator />
    </NavigationContainer>
  );
};

export default Routes;
