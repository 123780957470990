import React, { useState, useEffect } from "react";
import { Image, View, StyleSheet, Text, ActivityIndicator, TouchableOpacity, PixelRatio, ImageBackground, Modal } from "react-native";
import { useGlobalState } from "../../shared/state";
import { Fonts } from "../../styles/font.styles";
import { background, Icons } from "../../assets";
import { Styles } from "../../styles";
import SizeScale from "../../components/SizeScale/SizeScale";
import * as WebBrowser from "expo-web-browser";
import { useNavigation } from "@react-navigation/native";
import { insoleCategories } from "../InsoleRecommendation/InsoleRecommendation";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import ProductService from "../../shared/services/products-service";
import { Picker } from "@react-native-picker/picker";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { getValidIndex, I18N } from "../../shared/util";
import { addItemToCart, addNewCart, doesCartTypeExist, findProductBySize, findProductImageBySize, getCartIdByType, getStandardCartId } from "../Order/cartFunctions";
import { Cart } from "../../shared/model/types";
import API from "../../shared/api";
import _ from "lodash";

const ProductDetail: React.FC<any> = (route): JSX.Element => {
  const { params } = route.route;
  const [language] = useGlobalState("language");
  const [currentMeasurements] = useGlobalState("currentMeasurements");
  const [currentScanner] = useGlobalState("currentScanner");
  const [currentUser] = useGlobalState("currentUser");
  const [allProducts] = useGlobalState("allProducts");
  const [Colors] = useGlobalState("colors");
  const [, setIsOrthoOrder] = useGlobalState("isOrthoOrder");
  const [selectedShoe, setSelectedShoe] = useGlobalState("selectedShoe");
  const [customerStoreID] = useGlobalState("customerStoreID");
  const [currentScan] = useGlobalState("currentScan");
  const [carts, setCarts] = useGlobalState("carts");
  const [industryPartner] = useGlobalState("industryPartner");
  const [productStoreID] = useGlobalState("productStoreID");
  const [isFullCatalog] = useGlobalState("isFullCatalog");
  const [eventName] = useGlobalState("eventName");
  const i18n = I18N(language);
  const navigation = useNavigation();
  const [product] = useState<any>((params && params.product) || selectedShoe || undefined);
  const [warehouseBins, setWarehouseBins] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [sizes, setSizes] = useState<React.ReactNode[]>();
  const [availableSizes, setAvailableSizes] = useState<any>();
  const [shoeSize, setShoeSize] = useState<string>("");
  const [, setInsole] = useState<string>("");
  const [insoleGtin, setInsoleGtin] = useState<string>(product.isInsole && product?.gtin);
  const [includeInsole, setIncludeInsole] = useState(false);
  const [, setOrderMessage] = useState<string>("");
  const [, setOrderProcessResult] = useState<"SUCCESS" | "FAILURE">();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isInsoleDetail = params && params.isInsole;
  const cartStoreID = industryPartner.store_id || customerStoreID || currentScanner.storeid;
  const productStoreId = productStoreID || customerStoreID || currentScanner.storeid;
  let cartItemStorePartner: string;

  if (industryPartner) {
    if (industryPartner && isFullCatalog) {
      cartItemStorePartner = "Elten";
    } else {
      cartItemStorePartner = industryPartner.partner;
    }
  } else {
    cartItemStorePartner = currentScanner.partner;
  }
  const isSSV = currentScanner.partner && currentScanner.partner?.toLowerCase() === "ssv";
  const isElten = currentScanner.partner && currentScanner.partner?.toLowerCase() === "elten";

  const primarySize: any = product.leading_system === "eu" ? product.size.eu : product.leading_system === "uk" ? product.size.uk : product.size.us;
  const secondarySize: any =
    currentScanner.enableSecondarySize || !("enableSecondarySize" in currentScanner)
      ? product.leading_system === "eu"
        ? product.size.secondary_eu
        : product.leading_system === "uk"
        ? product.size.secondary_uk
        : product.size.secondary_us
      : undefined;
  const price: any = product.price || undefined;
  const salePrice: any = product.salePrice || undefined;

  const EnableOrderButton: boolean =
    currentScanner.brandsToOrder && currentScanner.brandsToOrder.length > 0
      ? currentScanner.brandsToOrder.toLocaleString().toLowerCase().split(",").includes(params.product.brand.toLowerCase())
        ? true
        : false
      : true;
  
  const insoleCategoryIndex = currentMeasurements
    ? Math.min(
        getValidIndex(currentMeasurements.measurements[0].insole_recommendation),
        getValidIndex(currentMeasurements.measurements[1].insole_recommendation)
      ) === Infinity
      ? 1 // If both indices are -1, return 1
      : Math.min(
          getValidIndex(currentMeasurements.measurements[0].insole_recommendation),
          getValidIndex(currentMeasurements.measurements[1].insole_recommendation)
        )
    : 1;

  // if categoryToRecommend list is empty or undefined we display isoles for all product-category
  const isRecommendedForCategory = (): boolean =>
    !currentScanner.insoleRecommendation.categoryToRecommend
      ? true
      : currentScanner.insoleRecommendation.categoryToRecommend.includes(product.categoryName?.toLowerCase()) || !currentScanner.insoleRecommendation.categoryToRecommend.length
      ? true
      : false;

  const displayInsoleRecommendation: boolean =
    insoleCategories[insoleCategoryIndex] !== "none" && insoleCategories[insoleCategoryIndex] !== "special" && currentScanner.insoleRecommendation && isRecommendedForCategory();

  useEffect(() => {
    if (product) {
      setLoading(false);
      setSelectedShoe(product);
      if (currentScanner.displayStock) getProductWhereHouseBin();
    }
  }, []);

  const getProductWhereHouseBin = () => {
    var warehouseBinsArray: any[] = [];
    allProducts.map((products) => {
      products.details.map((detail: any) => {
        if (product.gtin === detail.gtin && currentScanner["storeid"] === detail.store_id) detail.warehouse_bin ? warehouseBinsArray.push(detail.warehouse_bin) : undefined;
      });
    });
    setWarehouseBins(warehouseBinsArray);
  };
  const handleGtinValue = () => {
    if (isElten) {
      return insoleGtin;
    } else {
      return insoleCategories[insoleCategoryIndex];
    }
  };
  
  let insoleType: number;

  if (insoleCategoryIndex) {
    switch (insoleCategories[insoleCategoryIndex]) {
      case "low":
        insoleType = isSSV ? 9999999999991 : 1;
        break;
      case "mid":
        insoleType = isSSV ? 9999999999992 : 2;
        break;
      case "high":
        insoleType = isSSV ? 9999999999993 : 3;
        break;
      default:
        insoleType = isSSV ? 9999999999992 : 2;
    }
  }

  const orderProduct = () => {
    setIsOrthoOrder(false);
    const processOrder = async () => {
      const cartData: Cart = {
        type: "standard",
        ...(industryPartner && {industry_partner: industryPartner && industryPartner.store_id}),
        store_id: cartStoreID,
        store_partner: industryPartner.partner || currentScanner.partner,
        ...(currentUser && currentUser.employeeNumber
          ? { employee_number: currentUser.employeeNumber }
          : currentUser && currentUser.email
          ? { email: currentUser.email }
          : { anonymous_user_uuid: currentScan && currentScan.customer_uuid }),
        items: [],
        ...(eventName && {event_name: eventName}),
      };
      const cartItemData = {
        type: "shoe",
        store_id: productStoreId,
        store_partner: cartItemStorePartner,
        gtin: selectedShoe.gtin,
        itemName: selectedShoe.name,
        itemIcon: selectedShoe.image_url
      };
      const insoleCartItemData = {
        type: "standard-insole",
        store_id: productStoreId,
        store_partner: cartItemStorePartner,
        additional_product_gtin: handleGtinValue(),
        itemName: (currentScanner.insoleRecommendation.insoles.female || currentScanner.insoleRecommendation.insoles.unisex) && findProductBySize(currentScanner.insoleRecommendation.insoles, insoleGtin),
        itemIcon: (currentScanner.insoleRecommendation.insoles.female || currentScanner.insoleRecommendation.insoles.unisex) && findProductImageBySize(currentScanner.insoleRecommendation.insoles, insoleGtin),
      };
  
      if (isSSV) {
        const orderPosArray = [
          {
            Menge: 1,
            Artikelnr: selectedShoe.gtin,
          },
        ];
        if (includeInsole) {
          orderPosArray.push({
            Menge: 1,
            Artikelnr: insoleType,
          });
        }
        API.put(
          "https://ssvkroschkepov-dev.outsystemsenterprise.com/SHOEPoint_BE/rest/SP_RESTAPI/AddOrder",
          {
            ShoePass: currentUser.employeeNumber,
            ExternalID: currentUser.externeID,
            K_Debitor: currentScanner.kDebitor,
            OrderPos: orderPosArray,
          },
          {
            auth: {
              username: "SHOEPoint",
              password: "hCt73mGCDgUQ733",
            },
          }
        ).then((res) => {
          if (res.ErrorMessage) {
            navigation.navigate("Order", {orderProcessResult: "FAILURE"});
          } else {
            navigation.navigate("Order", {orderProcessResult: "SUCCESS"});
          }
        });
      } else {
        if (!currentUser) {
          navigation.navigate("OrthoRegisterModal", { INCLUDE_INSOLE: includeInsole, isInsoleOrder: product.isInsole, insoleGtin: insoleGtin });
        } else if (currentUser) {
          // If carts already exist, get the cart UUID and update it
          if (!_.isEmpty(carts)) {
            let cartUuid
            if(getStandardCartId(carts)){
              const cart = getStandardCartId(carts);
              cartUuid = cart;
            } else {
              const newCart = await ProductService.createCart(cartData);
              cartUuid = newCart.uuid;
              // Update the local state with the new cart
              addNewCart(setCarts, cartUuid, cartData);
            }
            
  
            if (product.isInsole) {
              addItemToCart(setCarts, cartUuid, insoleCartItemData);
            } else {
              addItemToCart(setCarts, cartUuid, cartItemData);
              if (includeInsole) {
                addItemToCart(setCarts, cartUuid, insoleCartItemData);
              }
            }
            navigation.navigate("CartModal");
          } else {
            // If no carts exist, create a new cart first
            try {
              const newCart = await ProductService.createCart(cartData);
              const cartUuid = newCart.uuid;
  
              // Update the local state with the new cart
              addNewCart(setCarts, cartUuid, cartData);
  
              if (product.isInsole) {
                addItemToCart(setCarts, cartUuid, insoleCartItemData);
              } else {
                addItemToCart(setCarts, cartUuid, cartItemData);
                if (includeInsole) {
                  addItemToCart(setCarts, cartUuid, insoleCartItemData);
                }
              }
              navigation.navigate("CartModal");
            } catch (error) {
              console.error("ERROR creating cart ", error);
              setOrderProcessResult("FAILURE");
              setOrderMessage(i18n.t("Order.failureMessage"));
            }
          }
        } else {
          setOrderProcessResult("FAILURE");
          setOrderMessage(i18n.t("Order.failureMessage"));
        }
      }
    };
    processOrder()
  };

  const orthoOrderProduct = async () => {
    setIsOrthoOrder(true);
    if (currentUser && currentUser.dateOfBirth && currentUser.firstName && currentUser.lastName) {
      const cart: Cart[] = doesCartTypeExist(carts, "elten-ortho")
      if (cart.length > 0) {
        let isCartCreated = false;
  
        cart.forEach((orthoCart: Cart) => {
          if (orthoCart.industry_partner === industryPartner.store_id) {
            isCartCreated = true; // Cart already exists for the store
          }
        });

        if (!isCartCreated) {
          const cartData = {
            // TODO: change this to a variable when it is clarified
            type: "elten-ortho",
            industry_partner: industryPartner && industryPartner.store_id,
            store_id: industryPartner.store_id || customerStoreID || currentScanner.storeid,
            ...(currentUser.employeeNumber ? { employee_number: currentUser.employeeNumber } : { anonymous_user_uuid: currentScan.customer_uuid }),
            items: [],
            ...(eventName && {event_name: eventName}),
          };
          const newCart = await ProductService.createCart(cartData);
          const cartUuid = newCart.uuid;
          // Update the local state with the new cart
          addNewCart(setCarts, cartUuid, cartData);
          selectedShoe.suitableOrthoAdaption
            ? navigation.navigate("OrthopedicShoesOptionsModal", {cartUUID: cartUuid})
            : setIsModalVisible(true);
        } else {
          const cartUUID = getCartIdByType(carts, "elten-ortho")
        Object.values(carts[cartUUID].items).map((cartItem: any) => {
          cartItem.type === "orthopedic-insole" && selectedShoe.suitableOrthoAdaption
          ? navigation.navigate("OrthopedicShoesOptionsModal", {cartUUID: cartUUID})
          : setIsModalVisible(true);
        })
        }
      } else {
        console.log("CHECK 3")
        navigation.navigate("OrthopedicInsoleOptionsModal");
      }
    } else {
      console.log("CHECK 4")
      navigation.navigate("OrthoRegisterModal");
    }
  };

  const customiseProduct = () => {
    navigation.navigate("CustomiseProducOrder", { GTIN: product?.gtin });
  };
  const openShop = (gtin?: string, size?: string) =>
    WebBrowser.openBrowserAsync(`https://desma.expivi.com/product/perfectknit3d/?gtin=${gtin}&size=${size}`, { windowFeatures: { width: 1200, height: 1000 } }).catch((err) =>
      console.error("An error occurred", err)
    );

  useEffect(() => {
    const fetchAvailableSizes = async () => {
      try {
        const response = product && (await ProductService.getStoreProductsbyMpn(product?.storeID, product?.mpn));
        const availableSizes = response[0].details;
        setAvailableSizes(availableSizes);
      } catch (error) {
        console.error("Error fetching insole contour IDs:", error);
      }
    };

    !product.isInsole && fetchAvailableSizes();
  }, [product]);

  useEffect(() => {
    let sizesArray: JSX.Element[] = [<Picker.Item label="" value="" key="" />];
    let newShoeSize = "";

    if (availableSizes && availableSizes.length > 0) {
      availableSizes.forEach((size: any) => {
        const sizeValue = product.leading_system === "eu" ? size.eu : product.leading_system === "uk" ? size.uk : size.us;
        sizesArray.push(<Picker.Item label={sizeValue} value={size.gtin} key={size.gtin} />);

        if (sizeValue === primarySize) {
          newShoeSize = size.gtin;
        }
      });
    } else if (product.isInsole) {
      Object.keys(product.insoleSizes).map((size) => {
        sizesArray.push(<Picker.Item label={size} value={product.insoleSizes[size]} key={product.insoleSizes[size]} />);
        if (size === product.size) {
          newShoeSize = product.insoleSizes[size];
        }
      });
    }

    setSizes(sizesArray);

    // If primarySize exists in availableSizes, set it as the selected shoeSize
    if (newShoeSize) {
      setShoeSize(newShoeSize);
    } else if (sizesArray.length > 1) {
      // Otherwise, default to the first available size
      setShoeSize(sizesArray[1]?.props.value || "");
    }
  }, [availableSizes]);

  const handleSizeSelection = (selectedItem: any) => {
    if (product.isInsole) {
      setShoeSize(selectedItem);
      setInsoleGtin(selectedItem);
    } else if (includeInsole) {
      const sizeObject = availableSizes.find((size: any) => size.gtin === selectedItem);
      setShoeSize(sizeObject.gtin);
      const stateSizeObject = selectedShoe.size;
      stateSizeObject.eu = sizeObject.eu;
      setSelectedShoe((prev: any) => ({ ...prev, size: stateSizeObject, gtin: selectedItem }));
      setInsoleGtin(extensionProductData.sizes[sizeObject.eu]);
    } else {
      const sizeObject = availableSizes.find((size: any) => size.gtin === selectedItem);
      setShoeSize(sizeObject.gtin);
      const stateSizeObject = selectedShoe.size;
      stateSizeObject.eu = sizeObject.eu;
      setSelectedShoe((prev: any) => ({ ...prev, size: stateSizeObject, gtin: selectedItem }));
    }
  };

  useEffect(() => {
    if (currentMeasurements) {
      var insoleCategoryIndex = Math.min(
        insoleCategories.indexOf(currentMeasurements.measurements[0].insole_recommendation),
        insoleCategories.indexOf(currentMeasurements.measurements[1].insole_recommendation)
      );
      setInsole(insoleCategories[insoleCategoryIndex]);
    }
  }, [currentMeasurements]);

  const genderForInsoleFilter = product && product.productGender === "f" ? "female" : "unisex";
  var newInsoleDataStructure: any = currentScanner.insoleRecommendation && currentScanner.insoleRecommendation.insoles[genderForInsoleFilter];
  var extensionProductData: any = newInsoleDataStructure
    ? newInsoleDataStructure[insoleCategories[insoleCategoryIndex]]
    : currentScanner.insoleRecommendation && currentScanner.insoleRecommendation.insoles.find((insole: any) => insole.id.toLowerCase() === `${insoleCategories[insoleCategoryIndex]}`);

  const handleIncludeInsole = () => {
    const shoeSize = selectedShoe.size && selectedShoe.size.eu
    setIncludeInsole(!includeInsole);
    extensionProductData && extensionProductData.sizes && setInsoleGtin(extensionProductData.sizes[shoeSize]);
  }
  /**
   * @description function to render the extension card
   */
  const renderExtensionCard = () => {
    return (
      <View style={styles.extensionCard}>
        <Text style={[styles.productCardNameText, { textAlign: "center", marginTop: 4 }]}>BESTELLEN</Text>
        <View style={styles.advertisingProductCard}>
          {extensionProductData ? (
            <>
              {product && (product.suitableStandardInsole || product.suitableStandardInsole === null || product.suitableStandardInsole === undefined) && !isInsoleDetail && (
                <>
                  <View style={styles.recommendationDetailContainer}>
                    <Image source={{ uri: extensionProductData.image }} style={styles.imageContainer} />
                    <Text style={[styles.productCardNameText, { textAlign: "center" }]}>{extensionProductData.name}</Text>
                    {extensionProductData.price != undefined && <Text style={styles.productCardPriceText}>{`${(i18n.t("price"))}: ${extensionProductData.price}€`}</Text>}
                  </View>

                  {currentScanner.enableOrder && EnableOrderButton && (currentScanner.insoleRecommendation.insoles.female || currentScanner.insoleRecommendation.insoles.unisex) && (
                    <View style={styles.checkboxWrapper}>
                      <BouncyCheckbox
                        innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                        fillColor={Colors.mainColorLight.color}
                        isChecked={includeInsole}
                        onPress={() => handleIncludeInsole()}
                        text={i18n.t("addItem")}
                        textStyle={styles.checkBoxText}
                      />
                    </View>
                  )}
                </>
              )}
            </>
          ) : (
            <Text style={[styles.productCardNameText, { textAlign: "center" }]}>{i18n.t("insole.recommendation.0.conclusion")}</Text>
          )}
        </View>
        {currentScanner.enableExpiviShop && product.brand === "DESMA" ? (
          <TouchableOpacity style={styles.basketContainer} onPress={() => openShop(product?.gtin, primarySize)}>
            <View style={styles.basketContainerView}>
              <Image source={Icons.basketIcon} style={styles.basketIcon}></Image>
              <Text style={styles.basketContainerText}>{i18n.t("expiviConfigurator")}</Text>
            </View>
          </TouchableOpacity>
        ) : (
          <View style={styles.buttonContainer}>
            {currentScanner.enableOrder && EnableOrderButton && (
              <TouchableOpacity style={[styles.button, { margin: 0 }, styles.halfWidth]} onPress={() => (product?.msi && currentScanner.partner.toLowerCase() === 'steitz' ? customiseProduct() : orderProduct())}>
                <View style={styles.basketContainerView}>
                  <Image source={Icons.basketIcon} style={styles.basketIcon} />
                  <Text style={styles.basketContainerText}>{i18n.t("order now")}</Text>
                </View>
              </TouchableOpacity>
            )}
            {currentScanner && currentScanner.enableOrthopedicButton && !product.isInsole && (
              <TouchableOpacity style={[styles.button, { margin: 0 }, styles.halfWidth]} onPress={() => orthoOrderProduct()}>
                <View style={styles.basketContainerView}>
                  <Image source={Icons.basketIcon} style={styles.basketIcon} />
                  <Text style={styles.basketContainerText}>{i18n.t("orthoOrder")}</Text>
                </View>
              </TouchableOpacity>
            )}
            {currentScanner.enableOrder && EnableOrderButton && (
              <View style={[styles.button, styles.halfWidth, { margin: 0, flexWrap: "wrap" }]}>
                <Text>{i18n.t("listOfSizes")}</Text>
                <Picker style={styles.picker} selectedValue={shoeSize} onValueChange={(itemValue) => handleSizeSelection(itemValue)}>
                  {sizes}
                </Picker>
              </View>
            )}
          </View>
        )}
      </View>
    );
  };

  if (isLoading) {
    return (
      <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
        <LayoutWrapper showHeaderCloseButton={false} showBackButton={true} showHeaderLogo={true}>
          <View style={styles.loader}>
            <ActivityIndicator size="large" color={Colors.mainColorLight.color} />
          </View>
        </LayoutWrapper>
      </ImageBackground>
    );
  }
  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true}>
        <View style={styles.wrapper}>
        <Modal
          transparent
          animationType="slide"
          visible={isModalVisible}
          onRequestClose={() => setIsModalVisible(false)}
        >
          <View style={styles.modalOverlay}>
            <View style={styles.modalContent}>
              <Text style={styles.modalText}>{i18n.t("orthoInsolePopupText")}</Text>
              <TouchableOpacity style={[styles.button, { backgroundColor: Colors.mainColorDark.color, alignSelf: "center", width: "25%" }]} onPress={() => setIsModalVisible(false)}>
                <Text style={[styles.buttonText, { color: "#ffffff" }]}>{i18n.t("closeButton")}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
          <View style={styles.productContainer}>
            {product && (
              <View style={styles.card}>
                <View style={styles.productImageWrapper}>
                  <View style={styles.imageGallery}>
                    <Image source={{ uri: product.image_url }} style={styles.productImageContainer} />
                  </View>
                </View>
                <View style={styles.productDetailsWrapper}>
                  <View style={styles.productCardDetails}>
                    <Text style={styles.productDescriptionText}>{product.description}</Text>
                    <Text adjustsFontSizeToFit style={styles.productCardNameText}>
                      {product.name}
                    </Text>
                    {product.width_name != undefined && <Text style={styles.widthNameText}>{product.width_name}</Text>}
                    {product.price != undefined && (
                      <View style={{ flexDirection: "row" }}>
                        <Text style={styles.productCardPriceText}>{`${i18n.t("price")}: `}</Text>
                        {salePrice && price && salePrice < price ? (
                          <>
                            <Text style={[styles.productCardPriceText, { textDecorationLine: "line-through" }]}>{`${price}€ `}</Text>
                            <Text style={[styles.productCardPriceText, { color: "red" }]}>{`${salePrice}€`}</Text>
                          </>
                        ) : (
                          <Text style={styles.productCardPriceText}>{`${price}€`}</Text>
                        )}
                      </View>
                    )}
                    <Text style={styles.productCardManufacturerText}>
                      {product.brand} - {selectedShoe.gtin || product.gtin}
                    </Text>
                    {warehouseBins.length > 0 && <Text style={styles.productCardPriceText}>{warehouseBins.map((w) => `${w}  `)}</Text>}
                  </View>
                  <View style={styles.sizeWrapper}>
                    <View style={styles.soleIconContainer}>
                      <Image source={Icons.soleIcon} style={styles.soleIcon}></Image>
                      <Text style={styles.soleIconText}>{product.last_width_category}</Text>
                    </View>
                    <View style={[styles.sizeContainer, { backgroundColor: Colors.mainColorDark.color }]}>
                      <SizeScale
                        caption={"EU"}
                        valueCount={5}
                        categories={
                          secondarySize
                            ? secondarySize < primarySize
                              ? [
                                  { name: secondarySize, value: secondarySize },
                                  { name: primarySize, value: primarySize },
                                ]
                              : [
                                  { name: primarySize, value: primarySize },
                                  { name: secondarySize, value: secondarySize },
                                ]
                            : [
                                { name: "", value: 0 },
                                { name: primarySize, value: 2 },
                                { name: "", value: 4 },
                              ]
                        }
                        values={{ left: 2 }}
                      />
                    </View>
                  </View>
                </View>
              </View>
            )}
          </View>
          <View style={styles.subContainer}>{renderExtensionCard()}</View>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default ProductDetail;

const styles = StyleSheet.create({
  wrapper: {
    height: "95%",
    width: "80%",
    flexDirection: "row",
    alignSelf: "center",
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
    shadowOpacity: 0.16,
    elevation: 1,
    opacity: 1,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: "30%",
    height: "15%",
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    alignItems: 'center',
  },
  modalText: {
    marginBottom: 15,
    fontSize: 18,
  },
  buttonText: {
    fontFamily: "Montserrat-Regular",
    fontWeight: "bold",
    fontSize: 14,
    textAlign: "center",
    color: "#000000",
    textTransform: "uppercase",
  },
  productContainer: {
    height: "100%",
    width: "65%",
    shadowColor: "#000",
    shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
    shadowOpacity: 0.16,
    elevation: 1,
    opacity: 1,
  },
  subContainer: {
    height: "100%",
    width: "35%",
    justifyContent: "flex-end",
  },
  card: {
    height: "98.5%",
    justifyContent: "space-between",
    backgroundColor: "white",
  },
  extensionCard: {
    height: "98.5%",
    justifyContent: "space-between",
  },
  productImageWrapper: {
    height: "65%",
  },
  sizeWrapper: {
    height: "100%",
    width: "30%",
    flexDirection: "row",
  },
  soleIconContainer: {
    width: "50%",
    height: "60%",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-end",
  },
  soleIcon: {
    width: "100%",
    height: "80%",
    resizeMode: "contain",
    zIndex: 10,
  },
  soleIconText: {
    ...Fonts.mSemiBold,
    fontSize: 16,
    fontWeight: "900",
    position: "absolute",
    right: "45%",
    bottom: "40%",
    zIndex: 11,
  },
  imageContainer: {
    width: "90%",
    height: "90%",
    resizeMode: "contain",
  },
  productImageContainer: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  productDetailsWrapper: {
    height: "35%",
    width: "100%",
    flexWrap: "wrap",
    marginTop: 9,
  },
  productCardDetails: {
    height: "100%",
    width: "70%",
    alignSelf: "flex-end",
    padding: "2%",
  },
  productCardNameText: {
    textAlign: "left",
    fontFamily: "Montserrat-Black",
    fontSize: 16,
    marginTop: 10,
  },
  productCardPriceText: {
    fontFamily: "Montserrat-SemiBold",
    fontSize: 14,
  },
  productCardManufacturerText: {
    fontFamily: "Montserrat-Black",
    fontSize: 12,
  },
  sizeContainer: {
    alignSelf: "flex-end",
    height: "55%",
    width: "50%",
  },
  loader: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: "2%",
    paddingLeft: "1%",
  },
  productDescriptionText: {
    fontFamily: "Montserrat-Light",
    fontSize: 13,
    paddingBottom: "5%",
    overflow: "scroll",
  },
  basketContainer: {
    width: "98.9%",
    height: "20%",
    alignSelf: "flex-end",
    borderWidth: 1,
    borderColor: "#d6d7da",
    shadowColor: "#000",
    shadowOffset: {
      width: 0.2,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1,
    elevation: 1,
    backgroundColor: "white",
    marginTop: "2%",
  },
  button: {
    height: "48%",
    margin: "1%",
    borderWidth: 1,
    borderColor: "#d6d7da",
    shadowColor: "#000",
    shadowOffset: {
      width: 0.2,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1,
    elevation: 1,
    backgroundColor: "white",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    width: "100%",
    height: "40%",
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  halfWidth: {
    width: "48%",
  },
  fullWidth: {
    width: "99%",
  },
  basketContainerView: {
    flex: 1,
    justifyContent: "space-evenly",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  basketIcon: {
    width: 45,
    height: 40,
    resizeMode: "contain",
    alignSelf: "center",
  },
  basketContainerText: {
    fontFamily: "Montserrat-Black",
    fontSize: 14,
  },
  advertisingProductCard: {
    justifyContent: "space-evenly",
    alignItems: "center",
    alignSelf: "center",
    height: "55%",
    width: "90%",
  },
  imageGallery: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  secondaryRecomendationText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 15,
    textAlign: "center",
  },
  widthNameText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 14,
    fontWeight: "600",
    right: "-36%",
    bottom: 140,
    position: "absolute",
  },
  picker: {
    width: "100%",
    height: "45%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    border: "none",
  },
  recommendationDetailContainer: {
    height: "85%",
    width: "100%",
    padding: 5,
    alignItems: "center",
  },
  checkboxWrapper: {
    justifyContent: "center",
    width: "95%",
    flexDirection: "row",
    alignContent: "center",
  },
  checkBoxText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    color: "#000000",
    fontWeight: "100",
    textDecorationLine: "none",
  },
});

